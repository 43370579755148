<template>
  <span v-if="rangeObj.type">{{ getValue() }}</span>
  <div :class="['select-item', { 'small-select-item': isSmall }]" v-else>
    <a-select
      :class="['range-select', { 'price-select': rangeType == 'price' }]"
      v-model="formData[rangeKey]"
      @change="rangeChange"
      :showArrow="false"
      v-if="needRange"
    >
      <a-select-option v-for="item in Object.values(rangeTypeList)" :key="item.label" :value="item.value">
        <a-tooltip v-if="item.value == 'BETWEEN'">
          <span class="range-tooltip" slot="title">介于(包含)</span>
          <span>{{ item.label }}</span>
        </a-tooltip>
        <template v-else>
          {{ item.label }}
        </template>
      </a-select-option>
    </a-select>
    <div class="middle">
      <!-- 区间输入框 -->
      <template v-if="isSection(optionRangeType)">
        <input-number
          :formData="formData"
          :valueKey="rangeStartKey"
          :noBorder="true"
          :placeholder="needPlaceholder ? '请输入取值范围数值起始值(包含)' : ''"
        />
        <span :class="[{ 'suffix-input': suffix }]" v-if="suffix">{{ suffix }}</span>
        <div class="line">~</div>
        <input-number
          :formData="formData"
          :valueKey="rangeEndKey"
          :noBorder="true"
          :placeholder="needPlaceholder ? '请输入取值范围数值结束值(包含)' : ''"
        />
      </template>
      <!-- 普通输入框 -->
      <input-number
        v-else
        :formData="formData"
        :valueKey="valueKey"
        :noBorder="true"
        :placeholder="needPlaceholder ? '请输入取值数值' : ''"
      />
      <span :class="[{ 'suffix-input': suffix }]" v-if="suffix">{{ suffix }}</span>
    </div>
    <a-select
      class="unit-select"
      :style="{ width: needRange ? null : '50%' }"
      v-if="needUnit"
      v-model="formData[unitKey]"
      :showArrow="false"
    >
      <a-select-option v-for="item in Object.values(unitTypeList)" :key="item.label" :value="item.value">{{
        item.label
      }}</a-select-option>
    </a-select>
  </div>
</template>

<script>
import InputNumber from '@/components/edit/input-number.vue'
import { createRangeTypeList, createMoneyTypeList } from '@/enums/rangeTypeList'
import { createPriceUnitTypeList } from '@/enums/unitTypeList'

export default {
  components: {
    InputNumber
  },
  model: {
    prop: 'rangeObj'
  },
  props: {
    rangeObj: {
      type: Object,
      default: () => ({})
    },
    needUnitValue: {
      type: Boolean,
      default: false
    },
    isSmall: {
      type: Boolean,
      default: false
    },
    suffix: {
      type: String,
      default: ''
    },
    needUnit: {
      type: Boolean,
      default: true
    },
    needRange: {
      type: Boolean,
      default: true
    },
    needPlaceholder: {
      type: Boolean,
      default: false
    },
    rangeType: {
      type: String,
      default: 'count'
    },
    optionRangeType: {
      type: String,
      default: ''
    },
    unitType: {
      type: String,
      default: 'count'
    },
    rangeKey: {
      type: String,
      default: 'range'
    },
    valueKey: {
      type: String,
      default: 'value'
    },
    rangeStartKey: {
      type: String,
      default: 'start'
    },
    rangeEndKey: {
      type: String,
      default: 'end'
    },
    unitKey: {
      type: String,
      default: 'unit'
    },
    // 需要注意，如果传了个null进来，需要处理值绑定的问题
    formData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      range: '',
      rangeTypeList: {},
      unitTypeList: {}
    }
  },
  mounted() {
    this.setRange()
  },
  watch: {
    rangeObj: {
      handler() {
        this.setRange()
      },
      deep: true
    }
  },
  methods: {
    setRange() {
      let rangeTypeList = this.setRangeList(this.rangeType)
      // 小的范围选择器，不包含 BETWEEN
      if (this.rangeType === 'count' && this.isSmall) {
        delete rangeTypeList.NOT_BETWEEN
        delete rangeTypeList.BETWEEN
      }
      this.rangeTypeList = { ...rangeTypeList }
      this.unitTypeList = this.setUnitList(this.unitType)
      // 如果有值，就需要更新到range
      if (this.formData[this.rangeKey]) {
        this.range = this.formData[this.rangeKey]
      }
      this.$set(this.rangeObj, 'unitLabel', this.unitTypeList[this.rangeObj.unit]?.label)
      this.$emit('input', this.rangeObj)
    },
    rangeChange(e) {
      this.range = e
      console.log(e)
    },
    setRangeList(type) {
      switch (type) {
        case 'count':
          return createRangeTypeList()
        default:
          return {}
      }
    },
    setUnitList(type) {
      switch (type) {
        case 'count':
          return createPriceUnitTypeList()
        case 'price':
          return createMoneyTypeList()
        default:
          return {}
      }
    },
    isSection(range) {
      return ['NOT_BETWEEN', 'BETWEEN'].includes(range)
    },
    // 外显的值
    getValue() {
      // 没有类型列表和值，就不向下执行（避免报错
      let item = this.rangeTypeList[this.rangeObj.type]
      if (this.rangeType == 'price') {
        item = this.rangeTypeList[this.rangeObj.unit]
      }
      if (!item) return ''

      let isSection = this.isSection(this.rangeObj.type)
      let rangeLabel = ''
      let value = ''
      let unitLabel = ''

      // 货币或范围
      if (this.rangeType === 'price') {
        rangeLabel = this.rangeTypeList[this.rangeObj.unit].label
      } else {
        rangeLabel = this.rangeTypeList[this.rangeObj.type].label
      }

      // 值或区间
      if (isSection) {
        value = this.rangeObj.start || this.rangeObj.end ? `${this.rangeObj.start}~${this.rangeObj.end}` : ''
      } else {
        value = this.rangeObj.value ? `${this.needRange ? rangeLabel + ' ' : ''}${this.rangeObj.value}` : ''
      }

      // 需要单位，就拼接上单位
      if (this.needUnitValue) {
        unitLabel = this.unitTypeList[this.rangeObj.unit]?.label
      }
      return `${value} ${unitLabel ? unitLabel : ''}${this.suffix}`
    }
  }
}
</script>

<style lang="less">
.select-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  overflow: hidden;

  .box {
    background-color: #f7f7f7;
  }

  .unset {
    border-radius: 0;
    outline: unset;
    box-shadow: unset;
    border: unset;
  }

  input,
  .ant-select-selection {
    .unset();

    &:hover,
    &:focus,
    &:active {
      .unset();
    }
  }

  .ant-select {
    .ant-select-selection {
      .box();
    }

    &:first-child .ant-select-selection {
      border-radius: 4px 0 0 4px;
    }

    &:last-child .ant-select-selection {
      border-radius: 0 4px 4px 0;
    }
  }

  .line {
    .box();
    padding: 0 10px;
    height: 32px;
    line-height: 32px;
  }

  .middle {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .suffix-input {
      margin: 0 6px 0 0;
      color: #000000a6;
    }
  }

  .range-select {
    // width: 60px;
    width: 34px;
  }

  .unit-select {
    // width: 80px;
    width: 38px;
  }
}

.small-select-item {
  .range-select {
    width: 34px;
  }

  .price-select {
    width: 54px;
  }

  .unit-select {
    width: 34px;

    .ant-select-selection {
      .ant-select-selection__rendered {
        display: flex;
        justify-content: center;

        .ant-select-selection-selected-value {
          overflow: visible;
        }
      }

      .ant-select-arrow {
        right: 5px;
      }
    }
  }

  input {
    padding: 4px !important;
    height: 32px;
  }
}

.ant-select-dropdown-menu-item {
  padding: 5px 0px;
  text-align: center;
}
</style>
